.App {
    text-align: center;
    width: 700px;
    margin: auto;
    margin-top: 10px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.postCard {
    border-radius: 0.75em;
    border-color: darkgrey;
    border-style: solid;
    border-width: 1px;
    padding: 7px;
    margin-bottom: 15px;
}

.postBody {
    line-height: 15px;
    margin-top: 7px;
    color: darkgreen;
}

.postUser {
    color: darkblue;
}

.postTitle {
    line-height: 15px;
    color: darkgreen;
}

.postInput {
    position: fixed;
    top: 20px;
    right: 10px;
    background-color: coral;
    padding: 7px;
    border-radius: 0.75em;
    display: flex;
    flex-direction: column;
}
.postInput > * {
    margin-top: 7px;
}

.postButton {
}
